import React, { useState, useEffect } from "react";
import { tokenValidation } from "../../../helper/customizedHooks";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Tab,
  Table,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import {
  StyledSearchBarPlatforms,
  PlatformWrapper,
  PlatformTitleWrapper,
  StyledSearchIcon,
  StyledPaper,
  ProgressWrapper,
  FinalWrapperFromSsh,
  ToAccessTypography,
  SuccessWrapper,
  StyledAvatar,
  ButtonWrapperFromSsh,
  TableWrapper,
  StyledTabs,
} from "../FuiStyles";

import { isEmpty } from "lodash";

import DetailsDialog from "../Dialogs/DetailsDialogs";
import * as fuiRedux from "../../../redux/fui/index";
import RemoveIcon from "@material-ui/icons/Remove";
import { appNameProjectId } from "../Constants/ProductIds";
import PropTypes from "prop-types";
import AllInstances from "../InstancesFilteredByType/AllInstances";
import { PrimaryButton } from "../../common/CommonComponents/Buttons";
import { XSmallHeader } from "../../common/CommonStyles/CommonTypographyStyles";
import OrderRequestsList from "../OrderRequestsList";
import { Footer } from "../../common/Footer";
/**
 * Renders platform list tab in fui home.
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: AppStepper, FulfilmentUI
 */

const showInstanceName = (instanceId) => {
  switch (instanceId) {
    case appNameProjectId.managedPlatformId:
      return "Managed Platform";
    case appNameProjectId.dedicatedPlatformId:
      return "Dedicated Platform";
    case appNameProjectId.utkmPlatformId:
      return "UTKM Platform";
    case appNameProjectId.genWizardPlatformId:
      return "GenWizard Platform";
    case appNameProjectId.adPlatformId:
      return "AD Platform";
    case appNameProjectId.plainInstanceId:
      return "Plain Instance";
    case appNameProjectId.automaticTicketResolverId:
      return "ATR Platform";
    case appNameProjectId.eventOpsId:
      return "EventOps Platform";
    // case appNameProjectId.utkmPlatformId:
    //   return "Klewer & Quasar";
    case appNameProjectId.atrcaasId:
      return "ATR Platform (Caas)";
    case appNameProjectId.stormseerId:
      return "StormSeer";
    case appNameProjectId.pamInstanceId:
      return "PAM Platform (Plain)";
    case appNameProjectId.automationJourneyId:
      return "PAM Platform (AJ)";
    case appNameProjectId.intelligentOpportunityMinerId:
      return "PAM Platform (IOM)";
    case appNameProjectId.guidedTicketResolverId:
      return "PAM Platform (GTR)";
    case appNameProjectId.klewerId:
      return "PAM Platform (Klewer)";
    case appNameProjectId.quasarId:
      return "PAM Platform (Quasar)";
    case appNameProjectId.virtualDataScientistId:
      return "PAM Platform (VDS)";
    case appNameProjectId.splunkClusterPlatformId:
      return "Splunk Cluster Platform";
    case appNameProjectId.turingId:
      return "Quasar++";
    default:
      return "Unknown Instance";
  }
};


const PlatformList = ({
  setIsFinished,
  isNotProducts,
  updateASelectedInstance,
  routeName,
  isLoading,
  rowsReal,
  orderRequestsData,
  fetchInstanceData,
  fetchOrderRequestsData,
  history,
  email,
  classes,
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [openInstalledApps, setOpenInstalledApps] = useState(false);
  const [scrollDetails, setScrollDetails] = useState("paper");
  const [scrollInstalledApps, setScrollInstalledApps] = useState("paper");
  const [currentOrderId, setCurrentOrderId] = useState("");
  const [selectedInstanceId, setSelectedInstanceId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [orderRowsRealPerDate, setOrderRowsRealPerDate] = useState([]);
  const [sortedOrderRequestsData, setSortOrderRequestsData] = useState([]);
  const [filterInstanceType, setFilterInstanceType] = useState("All Platforms");
  const [tabsValues, setTabsValues] = useState(0);
  const [isPAMPlatform, setIsPAMPlatform] = useState(false);

  useEffect(() => email && fetchInstanceData(email), [email]);
  useEffect(() => email && fetchOrderRequestsData(email), [email]);
  
  //refresh the table per 4s
  useEffect(() => {
    if (email) {
      let timer = setInterval(() => {
        const apiCall = () => {
          tokenValidation();
          fetchInstanceData(email);
          fetchOrderRequestsData(email);
        };
        apiCall();
      }, 4000);
      return () => clearInterval(timer);
    }
  }, [email]);

  useEffect(() => {
    if (rowsReal) {
      let orderArray = rowsReal.sort(function (a, b) {
        let first = a.hasOwnProperty("CreatedDate")
          ? new Date(a.CreatedDate)
          : new Date(a.CreateDate);
        let seccond = b.hasOwnProperty("CreatedDate")
          ? new Date(b.CreatedDate)
          : new Date(b.CreateDate);
        return seccond - first;
      });

      setOrderRowsRealPerDate(orderArray);
    }
    if (orderRequestsData) {
      let orderArray = orderRequestsData.sort(function (a, b) {
        let first = a.hasOwnProperty("CreatedDate")
          ? new Date(a.CreatedDate)
          : new Date(a.CreateDate);
        let seccond = b.hasOwnProperty("CreatedDate")
          ? new Date(b.CreatedDate)
          : new Date(b.CreateDate);
        return seccond - first;
      });

      setSortOrderRequestsData(orderArray);
    }
  }, [email, rowsReal, orderRequestsData]);

  useEffect(() => {
    const PAMPlatforms = [
      "/fui/order/automationJourney",
      "/fui/order/automationPlanner",
      "/fui/order/intelligentOpportunityMiner",
      "/fui/order/guidedTicketResolver",
      "/fui/order/klewer",
      "/fui/order/quasar",
      "/fui/order/virtualDataScientist",
    ];
    const inPAM = !!PAMPlatforms.find((platform) =>
      history.location.pathname.includes(platform)
    );
    setIsPAMPlatform(inPAM);
    if (inPAM) {
      setFilterInstanceType("PAM Platform");
    }
  });

  // change Placed into Processing
  const mapPlacedToProcessing = (data) => {
    return data.filter((item) => {
      return item.status === "Placed"
        ? (item.status = "Processing")
        : item.status;
    });
  };

  // TA can install in both DP and MP, while DOA and ATR can only install in DP
  const showCorrectPlatform = (route, rows) => {
    switch (route) {
      case "ticketAnalysis":
        return filterAppJustShowDPAndMP(rows);
      case "webAnalytics":
        return filterAppShowDPAndMP(rows);
      case "sharepoint":
        return filterAppShowDP(rows);
      case "devOpsAnalytics":
        return filterAppShowDP(rows);
      case "automaticTicketResolver":
        return filterAppShowDP(rows);
      case "stormseer":
        return filterAppShowDP(rows);
      case "reportExtractor":
        return filterAppShowDP(rows);
      default:
        return filterNeedShow(rows);
    }
  };

  // filter the instance without app or license
  const filterNeedShow = (data) =>
    data.filter(
      (row) =>
        row.ProductUId === appNameProjectId.managedPlatformId ||
        row.ProductUId === appNameProjectId.dedicatedPlatformId ||
        row.ProductUId === appNameProjectId.genWizardPlatformId ||
        row.ProductUId === appNameProjectId.adPlatformId ||
        row.ProductUId === appNameProjectId.plainInstanceId ||
        row.ProductUId === appNameProjectId.automaticTicketResolverId ||
        row.ProductUId === appNameProjectId.eventOpsId ||
        row.ProductUId === appNameProjectId.atrcaasId ||
        row.ProductUId === appNameProjectId.stormseerId ||
        row.ProductUId === appNameProjectId.turingId ||
        row.ProductUId === appNameProjectId.pamInstanceId ||
        row.ProductUId === appNameProjectId.utkmPlatformId ||
        row.ProductUId === appNameProjectId.splunkClusterPlatformId
    );

  // filter the instance without PI, only have DP and MP
  const filterAppShowDPAndMP = (data) =>
    data.filter(
      (row) =>
        row.ProductUId === appNameProjectId.managedPlatformId ||
        row.ProductUId === appNameProjectId.dedicatedPlatformId ||
        row.ProductUId === appNameProjectId.genWizardPlatformId ||
        row.ProductUId === appNameProjectId.adPlatformId ||
        row.ProductUId === appNameProjectId.automaticTicketResolverId ||
        row.ProductUId === appNameProjectId.eventOpsId ||
        row.ProductUId === appNameProjectId.atrcaasId ||
        row.ProductUId === appNameProjectId.stormseerId
    );

  const filterAppJustShowDPAndMP = (data) =>
    data.filter(
      (row) =>
        row.ProductUId === appNameProjectId.managedPlatformId ||
        row.ProductUId === appNameProjectId.dedicatedPlatformId
    );

  // filter the instance without MP and PI, only have DP and MP
  const filterAppShowDP = (data) =>
    data.filter(
      (row) => row.ProductUId === appNameProjectId.dedicatedPlatformId
    );

  const tableRows = mapPlacedToProcessing(
    mapPayloadToState(showCorrectPlatform(routeName, orderRowsRealPerDate))
  );

  const handleClickOpenDetails = (scrollType, orderId) => () => {
    setOpenDetails(true);
    setScrollDetails(scrollDetails);
    setCurrentOrderId(orderId);
  };
  const handleClickOpenInstalledApps = (scrollType, orderId) => () => {
    setOpenInstalledApps(true);
    setScrollInstalledApps(scrollInstalledApps);
    setCurrentOrderId(orderId);
  };

  const handleChange = (event) => {
    const orderId = event.target.value;
    const newRowData = rowsReal.find((rowData) => rowData.OrderUId === orderId);
    setSelectedInstanceId(orderId);
    updateASelectedInstance(convertInstanceFormat(newRowData));
    setIsFinished(true);
  };

  const convertInstanceFormat = (rowData) => (isEmpty(rowData) ? {} : rowData);

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };
  const handleCloseInstalledApps = () => {
    setOpenInstalledApps(false);
  };

  let filteredInstances =
    tableRows &&
    tableRows.filter((item) => {
      //console.log(item)
      if (searchQuery === "*") {
        return item;
      } else if (
        item.orderId.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 ||
        item.clientName.toLowerCase().indexOf(searchQuery.toLowerCase()) !==
        -1 ||
        item.productName.toLowerCase().indexOf(searchQuery.toLowerCase()) !==
        -1 ||
        item.domainContextRoot
          .toLowerCase()
          .indexOf(searchQuery.toLowerCase()) !== -1 ||
        item.status.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
      ) {
        return item;
      } else {
        return null;
      }
    });

  const sortFilteredInstances = () => {
    var filtered = filteredInstances;
    //console.log(filtered)
    return filtered
      .filter((value) => value.status === "Processing")
      .concat(filtered.filter((value) => value.status !== "Processing"));
  };

  const selectedInstances = (event, value) => {
    if (history.location.pathname.includes("/fui/order")) {
      setIsFinished(false);
    }
    setTabsValues(value);
    if (value === 0) {
      setFilterInstanceType("All Platforms");
    } else if (value === 1) {
      setFilterInstanceType("Managed Platform");
    } else if (value === 2) {
      setFilterInstanceType("Dedicated Platform");
    } else if (value === 3) {
      setFilterInstanceType("PAM Platform");
    } else if (value === 4) {
      setFilterInstanceType("Plain Instance");
    } else if (value === 5) {
      setFilterInstanceType("ATR Platform");
    } else if (value === 6) {
      // setFilterInstanceType("Splunk Cluster Platform");
    // } else if (value === 7) {
      // setFilterInstanceType("StormSeer");
    // } else if (value === 8) {
      setFilterInstanceType("Quasar++");
    }else if (value === 7) {
      setFilterInstanceType("AD Platform");
    }else if (value === 8) {
      setFilterInstanceType("GenWizard Platform");
    }else if (value === 9) {
      setFilterInstanceType("EventOps Platform");
    }else if (value == 10){
      setFilterInstanceType("UTKM Platform");
    }
  };

  return (
    <div style={{ height: "90%" }}>
      <TableWrapper style={{ height: "100%" }}>
        <PlatformWrapper style={{ height: "10%", maxHeight: "10%" }}>
          <PlatformTitleWrapper>
            <XSmallHeader>Confirmed Orders</XSmallHeader>
          </PlatformTitleWrapper>
          <StyledSearchBarPlatforms
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            id="outlined-search"
            type="search"
            margin="normal"
            placeholder="Search Platforms"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StyledSearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </PlatformWrapper>
        {isLoading ? (
          <div style={{ height: "90%", maxHeight: "90%" }}>
            <ProgressWrapper
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </ProgressWrapper>
          </div>
        ) : isEmpty(tableRows) ? (
          <FinalWrapperFromSsh>
            <SuccessWrapper>
              <StyledAvatar>
                <RemoveIcon />
              </StyledAvatar>
              <ToAccessTypography>
                You haven't ordered any instance!
              </ToAccessTypography>
            </SuccessWrapper>
            <ButtonWrapperFromSsh>
              <PrimaryButton
                onClick={() => history.push("/fui/order")}
                data-cy="goToOrderAnInstance"
                label={"GO TO ORDER AN INSTANCE"}
                className={classes.FuiPrimaryButton}
              />
            </ButtonWrapperFromSsh>
          </FinalWrapperFromSsh>
        ) : (
          <>
            <StyledTabs
              //   style={{ height: "10%", maxHeight: "10%" }}
              value={isPAMPlatform ? 3 : tabsValues}
              onChange={selectedInstances}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              <Tab label="All Platforms" disabled={isPAMPlatform} />
              <Tab label="Managed Platform" disabled={isPAMPlatform} />
              <Tab label="Dedicated Platform" disabled={isPAMPlatform} />
              {<Tab label="PAM Platform" />}
              {history.location.pathname.includes("/fui/platforms") && (
                <Tab label="Plain Instance" />
              )}

              {history.location.pathname.includes("/fui/platforms") && (
                <Tab label="ATR Platform" />
              )}
              {/* <Tab label="Splunk Cluster Platform" disabled={isPAMPlatform} /> */}
              {/* {history.location.pathname.includes("/fui/platforms") && (
                <Tab label="StormSeer" />
              )} */}
              {history.location.pathname.includes("/fui/platforms") && (
                <Tab label="Quasar++" />
              )}
              {history.location.pathname.includes("/fui/platforms") && (
                <Tab label="AD Platform" />
              )}
              {history.location.pathname.includes("/fui/platforms") && (
                <Tab label="GenWizard Platform" />
              )}
              {history.location.pathname.includes("/fui/platforms") && (
                <Tab label="EventOps Platform" />
              )}
              {history.location.pathname.includes("/fui/platforms") && (
                <Tab label="Klewer & Quasar Platform" />
              )}
            </StyledTabs>
            <StyledPaper style={{ height: "80%", overflow: "auto" }}>
              <Table>
                {history.location.pathname.includes("/fui/platforms") ? (
                  <AllInstances
                    handleClickOpenInstalledApps={handleClickOpenInstalledApps}
                    handleClickOpenDetails={handleClickOpenDetails}
                    sortFilteredInstances={sortFilteredInstances}
                    instanceType={filterInstanceType}
                    screen="platforms"
                    classes={classes}
                    isPAM={isPAMPlatform}
                  />
                ) : (
                  <AllInstances
                    handleClickOpenInstalledApps={handleClickOpenInstalledApps}
                    handleClickOpenDetails={handleClickOpenDetails}
                    selectedInstanceId={selectedInstanceId}
                    filteredInstances={filteredInstances}
                    instanceType={filterInstanceType}
                    handleChange={handleChange}
                    screen="apps"
                    isLoading={isLoading}
                    classes={classes}
                    isPAM={isPAMPlatform}
                  />
                )}
              </Table>
              <DetailsDialog
                open={openDetails}
                handleClose={handleCloseDetails}
                scroll={scrollDetails}
                rowsReal={rowsReal}
                currentOrderId={currentOrderId}
                classes={classes}
                dialogName={"platform"}
              />
              <DetailsDialog
                open={openInstalledApps}
                handleClose={handleCloseInstalledApps}
                scroll={scrollInstalledApps}
                rowsReal={rowsReal}
                currentOrderId={currentOrderId}
                classes={classes}
                dialogName={"apps"}
              />
            </StyledPaper>
          </>
        )}
      </TableWrapper>
      {routeName === "platforms" ?
            <OrderRequestsList data={sortedOrderRequestsData}/>
      : ""}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {/* <Footer /> */}
    </div>
  );
};

PlatformList.propTypes = {
  /**
   * History of route library
   */
  history: PropTypes.object,
  /**
   *
   */
  setIsFinished: PropTypes.func,
  /**
   * Tells which platform list to render, the home page one or the one
   * when selecting an app to install on a lpatform
   */
  isNotProducts: PropTypes.bool,
  /**
   * Redux function to update an instance
   */
  updateASelectedInstance: PropTypes.func,
  /**
   * Routename of form
   */
  routeName: PropTypes.string,
  /**
   * Is the page currently loading
   */
  isLoading: PropTypes.bool,
  /**
   * Users email
   */
  email: PropTypes.string,
  /**
   * Information of each platform
   */
  rowsReal: PropTypes.array,
  /**
   * Information of each platform
   */
  orderRequestsData: PropTypes.array,
  /**
   * Redux function to fetch the instance data using an email.
   */
  fetchInstanceData: PropTypes.func,
  /**
   * Redux function to fetch the order requests data using an email.
   */
  fetchOrderRequestsData: PropTypes.func,
};


// map the data that need show in the table
const mapPayloadToState = (originData) =>
  originData.map((originRow) => ({
    orderItemUId: originRow.OrderItemUId,
    orderId: originRow.OrderUId,
    clientName: originRow.ClientName,
    productName: showInstanceName(originRow.ProductUId),
    status: originRow.Status,
    domainContextRoot: originRow.DomainContextRoot,
  }));

const MSTP = (state, ownProps) => ({
  isLoading: state.fui.isLoading,
  rowsReal: state.fui.instanceData,
  email: state.auth.userDetails.email,
  orderRequestsData: state.fui.orderRequestsData,
});

export default connect(MSTP, {
  updateASelectedInstance: fuiRedux.updateASelectedInstance,
  fetchInstanceData: fuiRedux.fetchInstanceData,
  fetchOrderRequestsData: fuiRedux.fetchOrderRequestsData,
})(withRouter(PlatformList));